import Vue from 'vue';
import './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import meta from 'vue-meta';

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  meta,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app');


Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA4dDIEpIxUxjIkdAtxwHmqyuwR-QQ2N0M',
  },
});
<template>
    <div id="app">
        <v-app style="
                    background: #FFFFFF;
                    color: #666666;
                  "

        >
            <v-toolbar fixed app clipped-left class="mobile-header-color hidden-md-and-up z3">

                <v-icon @click.stop="drawer = !drawer"
                        class="dontMark link"
                        large
                        dark
                >
                    menu
                </v-icon>
            </v-toolbar>
            <v-navigation-drawer
                :clipped="true"
                v-model="drawer"
                temporary
                fixed
                class="hidden-md-and-up"
                app
            >
                <mobile-menue></mobile-menue>

            </v-navigation-drawer>
            <v-content class="hidden-md-and-up">
                <v-container fill-height class="pt-0 pb-0 mt-0 mb-0 ">
                    <v-layout align-center row wrap>
                        <v-flex>
                            <v-container  class=" pt-0 z2">
                                <v-layout row wrap>
                                    <v-flex class="mt-5 ml-3 mr-3 z2 ">
                                        <v-alert
                                                :value="maintanance"
                                                type="info"
                                                transition="scale-transition"
                                                class="mt-5"
                                        >
                                            <v-container class=" pt-0">
                                                <v-layout row wrap>
                                                    <v-flex v-if="!maintananceMessage" @click="toggleMaintananceMessage" class="pt-3 link" xs8>
                                                        Bitte Lesen
                                                    </v-flex>
                                                    <v-flex v-if="maintananceMessage" @click="toggleMaintananceMessage" class="link" xs8>
                                                        <b><u>Wichtige Informationen zum Corona-Virus.</u></b><br>
                                                        Regelung für Medizinische Masseure gemäss Mitteilung  der OdA MM (Dachorganisation der Med. Masseure EFA/FA)<br>
                                                        Die Schweiz befindet sich in einer ausserordentlichen Lage. Der Bundesrat hat einschneidende Massnahmen ergriffen, um die Ausbreitung des Coronavirus einzudämmen, die Zahl der schweren Erkrankungen zu begrenzen und die Überlastung unseres Gesundheitssystems zu vermeiden.<br><br>

                                                        Regelung für Medizinische Masseure<br><br>

                                                        Gemäss Verordnung/Änderung vom 16. März 2020 unter Art. 6 Absatz 2 unter e.) ist klar geregelt, dass keine Massage erlaubt ist.<br><br>

                                                        - Jegliche Massagen, die nicht vom Arzt verordnet werden, dürfen nicht ausgeführt werden.<br>
                                                        - Patienten, welche zur Risikogruppe gehören dürfen unter keinen Umständen in die Praxis kommen.<br>
                                                        Ich bedaure dass ich aus oben genannten Gründen meine Praxis schliessen muss. Bin aber telefonisch 055 422.28.80, per Mail info@chicasa.ch, WhatsApp und SMS 078 653.88.31 erreichbar.<br>
                                                        <br>
                                                        So hoffe ich, dass wir bald alle wieder in unseren Alltag zurückkehren können und freue mich, euch bald wieder in meiner Praxis begrüssen zu können und wünsche allen gute Gesundheit.
                                                        <br><br>
                                                        Herzlich<br><br>

                                                        Sandra Oechslin-Seiler
                                                    </v-flex>
                                                    <v-flex xs2 v-if="!maintananceMessage" @click="toggleMaintananceMessage" class="pt-3 link" >
                                                        <v-icon >arrow_drop_down</v-icon>
                                                    </v-flex>
                                                    <v-flex xs2 v-if="maintananceMessage" @click="toggleMaintananceMessage" class="pt-3 link">
                                                        <v-icon>arrow_drop_up</v-icon>
                                                    </v-flex>
                                                    <v-flex  @click="closeMaintananceMessage" class="pt-3 link">
                                                        <v-icon @click="closeMaintananceMessage">close</v-icon>
                                                    </v-flex>
                                                </v-layout>
                                            </v-container>
                                        </v-alert>
                                    </v-flex>
                                    <v-flex xs12 class="overMobileHeader webkit-fix-content z2">
                                        <router-view/>
                                    </v-flex>
                                </v-layout>
                            </v-container>

                        </v-flex>
                    </v-layout>
                </v-container>
            </v-content>


            <!--
            mobile on top
            ------------------------------------------------------------------------------------------------------
            mini desktop below
            -->

            <v-container class="pt-0 mt-0 pb-0 mb-0 pl-0 header-height hidden-sm-and-down hidden-lg-and-up" app>
                <v-layout align-center>
                    <v-flex xs12>
                        <mini-desktop-menue class="centered" app ></mini-desktop-menue>
                    </v-flex>
                </v-layout>
            </v-container>


            <v-content class="hidden-sm-and-down hidden-lg-and-up">

                <div class="header-spacer-mini"></div>
                <v-container class="pt-0 mt-0 z1 content"
                             @mouseover="massagenMenuNotActive()"
                             fill-height
                >
                    <v-layout row wrap @mouseover="angeboteMenuNotActive()">
                        <v-flex xs8 offset-xs2 grow-shrink-0 class="main main-bg z1" >
                            <v-alert
                                    :value="maintanance"
                                    type="info"
                                    transition="scale-transition"
                                    outline
                            >
                                <v-container class="pt-0">
                                    <v-layout row wrap>
                                        <v-flex v-if="!maintananceMessage" @click="toggleMaintananceMessage" class="pt-3 link" xs8>
                                            Bitte Lesen
                                        </v-flex>
                                        <v-flex v-if="maintananceMessage" @click="toggleMaintananceMessage" class="link" xs8>
                                            <b><u>Wichtige Informationen zum Corona-Virus.</u></b><br>
                                            Regelung für Medizinische Masseure gemäss Mitteilung  der OdA MM (Dachorganisation der Med. Masseure EFA/FA)<br>
                                            Die Schweiz befindet sich in einer ausserordentlichen Lage. Der Bundesrat hat einschneidende Massnahmen ergriffen, um die Ausbreitung des Coronavirus einzudämmen, die Zahl der schweren Erkrankungen zu begrenzen und die Überlastung unseres Gesundheitssystems zu vermeiden.<br><br>

                                            Regelung für Medizinische Masseure<br><br>

                                            Gemäss Verordnung/Änderung vom 16. März 2020 unter Art. 6 Absatz 2 unter e.) ist klar geregelt, dass keine Massage erlaubt ist.<br><br>

                                            - Jegliche Massagen, die nicht vom Arzt verordnet werden, dürfen nicht ausgeführt werden.<br>
                                            - Patienten, welche zur Risikogruppe gehören dürfen unter keinen Umständen in die Praxis kommen.<br>
                                            Ich bedaure dass ich aus oben genannten Gründen meine Praxis schliessen muss. Bin aber telefonisch 055 422.28.80, per Mail info@chicasa.ch, WhatsApp und SMS 078 653.88.31 erreichbar.<br>
                                            <br>
                                            So hoffe ich, dass wir bald alle wieder in unseren Alltag zurückkehren können und freue mich, euch bald wieder in meiner Praxis begrüssen zu können und wünsche allen gute Gesundheit.
                                            <br><br>
                                            Herzlich<br><br>

                                            Sandra Oechslin-Seiler
                                        </v-flex>
                                        <v-flex xs2 v-if="!maintananceMessage" @click="toggleMaintananceMessage" class="pt-3 link" >
                                            <v-icon >arrow_drop_down</v-icon>
                                        </v-flex>
                                        <v-flex xs2 v-if="maintananceMessage" @click="toggleMaintananceMessage" class="pt-3 link">
                                            <v-icon>arrow_drop_up</v-icon>
                                        </v-flex>
                                        <v-flex  @click="closeMaintananceMessage" class="pt-3 link">
                                            <v-icon @click="closeMaintananceMessage">close</v-icon>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-alert>
                            <router-view class="max-width z1"/>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-content>

            <!--
            mini Desktop above
            ------------------------------------------------------------------------------------------------------
            desktop on basement
            -->

            <!--Desktop Menue-->
            <v-container class="pt-0 mt-0 pb-0 mb-0 pl-0 header-height hidden-md-and-down" app>
                <v-layout align-center>
                    <v-flex xs12>
                        <desktop-menue class="centered" app ></desktop-menue>
                    </v-flex>
                </v-layout>
            </v-container>

            <!-- ------------------Massagen Nav drawer---------------------- -->
            <v-navigation-drawer
                    :clipped="clipped"
                    v-model="massagenMenuComp"
                    temporary
                    fixed
                    class="hidden-sm-and-down"
                    app
            >

                <desktop-massagen-menue></desktop-massagen-menue>

            </v-navigation-drawer>

            <v-content class="hidden-md-and-down pt-0">

                <div class="headerSpacer"></div>
                <v-container class="hidden-md-and-down pt-0 mt-0 z1 content"
                             @mouseover="massagenMenuNotActive()"
                             fill-height
                >
                    <v-layout row wrap @mouseover="angeboteMenuNotActive()">
                        <v-flex xs6 offset-xs3 grow-shrink-0 class="main main-bg hidden-xl-only z1" >
                            <v-alert
                                    :value="maintanance"
                                    type="info"
                                    transition="scale-transition"
                                    outline
                            >
                                <v-container class="pt-0">
                                    <v-layout row wrap>
                                        <v-flex v-if="!maintananceMessage" @click="toggleMaintananceMessage" class="pt-3 link" xs8>
                                            Bitte Lesen
                                        </v-flex>
                                        <v-flex v-if="maintananceMessage" @click="toggleMaintananceMessage" class="link" xs8>
                                            <b><u>Wichtige Informationen zum Corona-Virus.</u></b><br>
                                            Regelung für Medizinische Masseure gemäss Mitteilung  der OdA MM (Dachorganisation der Med. Masseure EFA/FA)<br>
                                            Die Schweiz befindet sich in einer ausserordentlichen Lage. Der Bundesrat hat einschneidende Massnahmen ergriffen, um die Ausbreitung des Coronavirus einzudämmen, die Zahl der schweren Erkrankungen zu begrenzen und die Überlastung unseres Gesundheitssystems zu vermeiden.<br><br>

                                            Regelung für Medizinische Masseure<br><br>

                                            Gemäss Verordnung/Änderung vom 16. März 2020 unter Art. 6 Absatz 2 unter e.) ist klar geregelt, dass keine Massage erlaubt ist.<br><br>

                                            - Jegliche Massagen, die nicht vom Arzt verordnet werden, dürfen nicht ausgeführt werden.<br>
                                            - Patienten, welche zur Risikogruppe gehören dürfen unter keinen Umständen in die Praxis kommen.<br>
                                            Ich bedaure dass ich aus oben genannten Gründen meine Praxis schliessen muss. Bin aber telefonisch 055 422.28.80, per Mail info@chicasa.ch, WhatsApp und SMS 078 653.88.31 erreichbar.<br>
                                            <br>
                                            So hoffe ich, dass wir bald alle wieder in unseren Alltag zurückkehren können und freue mich, euch bald wieder in meiner Praxis begrüssen zu können und wünsche allen gute Gesundheit.
                                            <br><br>
                                            Herzlich<br><br>

                                            Sandra Oechslin-Seiler
                                        </v-flex>
                                        <v-flex xs2 v-if="!maintananceMessage" @click="toggleMaintananceMessage" class="pt-3 link" >
                                            <v-icon >arrow_drop_down</v-icon>
                                        </v-flex>
                                        <v-flex xs2 v-if="maintananceMessage" @click="toggleMaintananceMessage" class="pt-3 link">
                                            <v-icon>arrow_drop_up</v-icon>
                                        </v-flex>
                                        <v-flex  @click="closeMaintananceMessage" class="pt-3 link">
                                            <v-icon @click="closeMaintananceMessage">close</v-icon>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-alert>
                            <router-view class="max-width z1"/>
                        </v-flex>
                        <v-flex xs4 offset-xs4 grow-shrink-0 class="main main-bg hidden-lg-and-down z1" >
                            <v-alert
                                    :value="maintanance"
                                    type="info"
                                    transition="scale-transition"
                                    outline
                            >
                                <v-container class=" pt-3">
                                    <v-layout row wrap>
                                        <v-flex v-if="!maintananceMessage" @click="toggleMaintananceMessage" class="pt-3 link" xs8>
                                            Bitte Lesen
                                        </v-flex>
                                        <v-flex v-if="maintananceMessage" @click="toggleMaintananceMessage" class="link" xs8>
                                            <b><u>Wichtige Informationen zum Corona-Virus.</u></b><br>
                                            Regelung für Medizinische Masseure gemäss Mitteilung  der OdA MM (Dachorganisation der Med. Masseure EFA/FA)<br>
                                            Die Schweiz befindet sich in einer ausserordentlichen Lage. Der Bundesrat hat einschneidende Massnahmen ergriffen, um die Ausbreitung des Coronavirus einzudämmen, die Zahl der schweren Erkrankungen zu begrenzen und die Überlastung unseres Gesundheitssystems zu vermeiden.<br><br>

                                            Regelung für Medizinische Masseure<br><br>

                                            Gemäss Verordnung/Änderung vom 16. März 2020 unter Art. 6 Absatz 2 unter e.) ist klar geregelt, dass keine Massage erlaubt ist.<br><br>

                                            - Jegliche Massagen, die nicht vom Arzt verordnet werden, dürfen nicht ausgeführt werden.<br>
                                            - Patienten, welche zur Risikogruppe gehören dürfen unter keinen Umständen in die Praxis kommen.<br>
                                            Ich bedaure dass ich aus oben genannten Gründen meine Praxis schliessen muss. Bin aber telefonisch 055 422.28.80, per Mail info@chicasa.ch, WhatsApp und SMS 078 653.88.31 erreichbar.<br>
                                            <br>
                                            So hoffe ich, dass wir bald alle wieder in unseren Alltag zurückkehren können und freue mich, euch bald wieder in meiner Praxis begrüssen zu können und wünsche allen gute Gesundheit.
                                            <br><br>
                                            Herzlich<br><br>

                                            Sandra Oechslin-Seiler
                                        </v-flex>
                                        <v-flex xs2 v-if="!maintananceMessage" @click="toggleMaintananceMessage" class="pt-3 link" >
                                            <v-icon >arrow_drop_down</v-icon>
                                        </v-flex>
                                        <v-flex xs2 v-if="maintananceMessage" @click="toggleMaintananceMessage" class="pt-3 link">
                                            <v-icon>arrow_drop_up</v-icon>
                                        </v-flex>
                                        <v-flex  @click="closeMaintananceMessage" class="pt-3 link">
                                            <v-icon @click="closeMaintananceMessage">close</v-icon>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-alert>
                            <router-view class="max-width z1"/>
                        </v-flex>
                    </v-layout>
                </v-container>

            </v-content>
            <v-footer fixed padless class=" pt-0 pb-0 footer " app>
                <v-container class=" pt-0 pb-0">
                    <v-layout row wrap>
                        <v-flex xs12 class="text-xs-center link z2" @click.stop="navPage('/Impressum')">
                            &copy; chicasa.ch {{ new Date().getFullYear() }} | Impressum
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-footer>

        </v-app>
    </div>
</template>

<script>
    import router from './router';
    import store from './store';
    import mobileMenue from './Components/MobileMenue.vue'
    import desktopMenue from './Components/Menues/DesktopMenue.vue'
    import miniDesktopMenue from './Components/Menues/MiniDesktopMenue.vue'

    import desktopMassagenMenue from './Components/Menues/DesktopMassagenMenue.vue'

    export default {
        name: 'app',
        data () {
            return {
                clipped: true,
                drawer: false,
                maintanance: false,
                maintananceMobile: false,
                maintananceMessage: false,
            }
        },
        components : {
            mobileMenue,
            desktopMenue,
            desktopMassagenMenue,
            miniDesktopMenue,
        },
        methods: {
            navPage (page) {
                router.push({path: page});
            },
            massagenMenuActive () {
                store.dispatch('massagenTrue')
            },
            massagenMenuNotActive () {
                store.dispatch('massagenFalse')
            },
            massagenMenuToggle () {
                store.dispatch('massagenToggle')
            },
            angeboteMenuActive () {
                store.dispatch('angeboteTrue')
            },
            angeboteMenuNotActive () {
                store.dispatch('angeboteFalse')
            },
            angeboteMenuToggle () {
                store.dispatch('angeboteToggle')
            },
            toggleDrawer() {
                this.drawer = !this.drawer
            },
            toggleMaintananceMessage() {
                this.maintananceMessage = !this.maintananceMessage;
            },
            toggleMaintananceMobile() {
                this.maintananceMobile = !this.maintananceMobile;
            },
            closeMaintananceMessage() {
                this.maintanance = false;
            }
        },
        computed: {
            home () {
                return this.$store.getters.home
            },
            footer () {
                return this.$store.getters.footer
            },
            massagenMenuComp() {
                return this.$store.getters.massagenMenu
            },
            angeboteMenuComp() {
                return this.$store.getters.angeboteMenu
            }
        }
    }
</script>


<style>
    @import 'assets/css/main.styl';

</style>


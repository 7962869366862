<template>

        <v-container  class="text-xs-left mt-0 pt-0">
            <v-layout column allign-center fill height>
                <v-flex d-flex xs9>

                    <v-container >
                        <v-layout row wrap class="text-xs-left pt-5 pb-3">
                            <v-flex xs12
                                    @click="navPage('/')"
                            >
                                <v-btn flat block class="text-xs-left" >
                                    <v-flex class="text-xs-left">
                                        Home
                                    </v-flex>

                                </v-btn>
                            </v-flex>
                            <v-flex xs12>
                                <v-btn flat block
                                       class="text-xs-left"
                                >
                                    <v-container class="ma-0 pa-0">
                                        <v-layout row wrap>
                                            <v-flex xs10 class="text-xs-left mt-1"
                                                    @click="navPage('/Massage')"
                                            >
                                                Massagen
                                            </v-flex>
                                            <v-flex
                                                    v-if="massagenMenuComp"
                                                    @click.stop="massagenMenuToggle()"
                                                    xs2
                                            >
                                                <v-icon class="dontMark link" >
                                                    arrow_drop_up
                                                </v-icon>
                                            </v-flex>
                                            <v-flex v-else
                                                    @click.stop="massagenMenuToggle()"
                                            >
                                                <v-icon class="dontMark link" xs2 >
                                                    arrow_drop_down
                                                </v-icon>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>


                                </v-btn>
                                <v-container v-if="massagenMenuComp"
                                             class="ma-0 pa-0 pl-3 submenu"
                                >
                                    <v-layout column >
                                        <v-flex  @click="navPage('/Massagen/Wirkung')">
                                            <v-btn flat block  class="submenu ">
                                                <v-flex class="text-xs-left">
                                                    Wirkung von Massagen
                                                </v-flex>

                                            </v-btn>
                                        </v-flex>

                                        <v-flex  @click="navPage('/Massagen/Klassisch')">
                                            <v-btn flat block  class="submenu ">
                                                <v-flex class="text-xs-left">
                                                    Klassische Massage
                                                </v-flex>

                                            </v-btn>
                                        </v-flex>
                                        <v-flex  @click="navPage('/Massagen/Ruecken')">
                                            <v-btn flat block  class="submenu">
                                                <v-flex class="text-xs-left">
                                                    Rückenmassage
                                                </v-flex>

                                            </v-btn>
                                        </v-flex>
                                        <v-flex  @click="navPage('/Massagen/Sport')">
                                            <v-btn flat block  class="submenu ">
                                                <v-flex class="text-xs-left">
                                                    Sportmassage
                                                </v-flex>

                                            </v-btn>
                                        </v-flex>
                                        <v-flex  @click="navPage('/Massagen/Akupunktur')">
                                            <v-btn flat block  class="submenu">
                                                <v-flex class="text-xs-left">
                                                    Akupunkturmassage
                                                </v-flex>

                                            </v-btn>
                                        </v-flex>
                                        <v-flex  @click="navPage('/Massagen/Bindegewebe')">
                                            <v-btn flat block class="submenu ">
                                                <v-flex class="text-xs-left">
                                                    Bindegewebemassage
                                                </v-flex>

                                            </v-btn>
                                        </v-flex>
                                        <v-flex  @click="navPage('/Massagen/Fussreflexzonen')">
                                            <v-btn flat block class="submenu">
                                                <v-flex class="text-xs-left">
                                                    Fussreflexzonenmassage
                                                </v-flex>

                                            </v-btn>
                                        </v-flex>
                                        <v-flex @click="navPage('/Massagen/Lymphdrainage')">
                                            <v-btn flat block class="submenu ">
                                                <v-flex class="text-xs-left">
                                                    Lymphdrainage
                                                </v-flex>

                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                            </v-flex>
                            <v-flex xs12
                                    @mouseover="massagenMenuNotActive()"
                                    @click="navPage('/Angebote')" >
                                <v-btn flat block >
                                    <v-flex class="text-xs-left">
                                        Gutscheine
                                    </v-flex>

                                </v-btn>
                            </v-flex>
                            <v-flex xs12
                                    @mouseover="massagenMenuNotActive()"
                                    @click="navPage('/person')" >
                                <v-btn flat block >
                                    <v-flex class="text-xs-left">
                                        Zur Person
                                    </v-flex>

                                </v-btn>
                            </v-flex>
                            <v-flex xs12
                                    @mouseover="massagenMenuNotActive()"
                                    @click="navPage('/Kontakt')" >
                                <v-btn flat block >
                                    <v-flex class="text-xs-left">
                                        Kontakt
                                    </v-flex>

                                </v-btn>
                            </v-flex>
                            <v-flex xs12
                                    @mouseover="massagenMenuNotActive()"
                                    @click="navPage('/Verordnung')" >
                                <v-btn flat block >
                                    <v-flex class="text-xs-left">
                                        Verordnung
                                    </v-flex>

                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-flex>
            </v-layout>
        </v-container>
</template>

<script>
    import router from '../router';
    export default {
        name: "MobileMenue",
        data () {
            return {
                massagenMenu: false,
                angeboteMenu: false,
            }
        },
        methods:{
            navPage (page) {
                router.push({path: page});
            },
            massagenMenuActive () {
                this.massagenMenu = true;
            },
            massagenMenuNotActive () {
                this.massagenMenu = false;
            },
            massagenMenuToggle () {
                this.massagenMenu = !this.massagenMenu;
            },
            angeboteMenuActive () {
                this.angeboteMenu = true;
            },
            angeboteMenuNotActive () {
                this.angeboteMenu = false;
            },
            angeboteMenuToggle () {
                this.angeboteMenu = !this.angeboteMenu;
            },
        },
        computed: {
            home () {
                return this.$store.getters.home
            },
            footer () {
                return this.$store.getters.footer
            },
            massagenMenuComp() {
                return this.massagenMenu
            },
            angeboteMenuComp() {
                return this.angeboteMenu
            }
        }
    }
</script>

<style scoped>

</style>
<template>
    <v-container
            class="ma-0 pa-3 pl-3"
    >
        <v-layout column
                  @mouseleave="massagenMenuNotActive()"
                  @mouseover="massagenMenuActive()"
        >

            <v-flex @click.stop="navPage('/Massage')"
                    class="text-xs-center pa-5 link">
                <h1>
                    Massagen
                </h1>
            </v-flex>
            <v-flex  @click="navPage('/Massagen/Wirkung')">
                <v-btn flat block>
                    <v-flex class="text-xs-left">
                        Wirkung von Massagen
                    </v-flex>
                </v-btn>
            </v-flex>
            <v-flex  @click="navPage('/Massagen/Klassisch')">
                <v-btn flat block>
                    <v-flex class="text-xs-left">
                        Klassische Massage
                    </v-flex>

                </v-btn>
            </v-flex>
            <v-flex  @click="navPage('/Massagen/Ruecken')">
                <v-btn flat block>
                    <v-flex class="text-xs-left">
                        Rückenmassage
                    </v-flex>

                </v-btn>
            </v-flex>
            <v-flex  @click="navPage('/Massagen/Sport')">
                <v-btn flat block>
                    <v-flex class="text-xs-left">
                        Sportmassage
                    </v-flex>

                </v-btn>
            </v-flex>
            <v-flex  @click="navPage('/Massagen/Akupunktur')">
                <v-btn flat block>
                    <v-flex class="text-xs-left">
                        Akupunkturmassage
                    </v-flex>

                </v-btn>
            </v-flex>
            <v-flex  @click="navPage('/Massagen/Bindegewebe')">
                <v-btn flat block>
                    <v-flex class="text-xs-left">
                        Bindegewebemassage
                    </v-flex>

                </v-btn>
            </v-flex>
            <v-flex  @click="navPage('/Massagen/Fussreflexzonen')">
                <v-btn flat block>
                    <v-flex class="text-xs-left">
                        Fussreflexzonenmassage
                    </v-flex>

                </v-btn>
            </v-flex>
            <v-flex @click="navPage('/Massagen/Lymphdrainage')">
                <v-btn flat block>
                    <v-flex class="text-xs-left">
                        Lymphdrainage
                    </v-flex>

                </v-btn>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import router from '../../router.js';
    import store from '../../store';
    export default {
        name: "DesktopMassagenMenue",
        data () {
            return {
            }
        },
        methods: {
            navPage (page) {
                router.push({path: page});
            },

            massagenMenuActive () {
                store.dispatch('massagenTrue')
            },
            massagenMenuNotActive () {
                store.dispatch('massagenFalse')
            },
        }
    }
</script>

<style scoped>

</style>
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        home: false,
        footer: false,
        massagenMenu: false,
        angeboteMenu: false,
        text: [
            {
                name: 'homeTitle',
                value: "Willkommen auf der Webseite von",
                target: "/",
                Post: false,
                date: 20181203,
            },

            {
                name: 'homeSpecial',
                value: "Chicasa",
                target: "/",
                Post: false,
                date: 20181203,
            },
            {
                name: 'homeAfter',
                value: "Die Gesundheits- und Massagepraxis in Einsiedeln <br>" +
                    "<v-img :src='StoneHome'>" +
                    "orandum est ut sit mens sana in corpore sano<br>" +
                    "es ist zu bitten, dass ein gesunder Geist in einem gesunden Körper sei",
                target: "/",
                Post: false,
                date: 20181203,
            },
            {
                name: 'homeMain',
                value: "Nehmen Sie sich etwas Zeit und informieren Sie sich. Gerne berate ich Sie persönlich <br>" +
                "<br><br>" +
                "Sandra Oechslin-Seiler" +
                "<br><br><br>" +
                "Chicasa steht für das Chi, die positive Lebensenergie und den Energiefluss und Casa, " +
                "das Haus bzw. der Körper, in welchem diese Energie fliesst.",
                target: "/",
                Post: false,
                date: 20181203,
            },
            {
                name: 'massagenUrsprungTitle',
                value: "Ursprung",
                target: "/",
                Post: false,
                date: 20181203,
            },
            {
                name: 'massagenUrsprungText',
                value: "Die Massage im weitesten Sinne ist eines der ältesten Heilmittel der Menschheit. Franz Kirchberg hat dies in seinem Buch treffend formuliert:\n" +
                "\n" +
                "\n" +
                "„Wie jeder Mensch instinktiv eine geschwollene und deshalb schmerzende oder gestoßene Stelle seines Körpers reibt oder drückt und so versucht, den durch die Spannung verursachten Schmerz zu mindern, so wird dieses instinktive Mittel wohl auch als Heilmittel zu allen Zeiten angewandt worden sein.“\n" +
                "\n" +
                "\n" +
                "– Franz Kirchberg: Handbuch der Massage und Heilgymnastik (1926)",
                target: "/",
                Post: false,
                date: 20181203,
            },
            {
                name: 'massagenEntwicklingTitle',
                value: "Entwicklung",
                target: "/",
                Post: false,
                date: 20181203,
            },
            {
                name: 'massagenEntwicklingText',
                value: "Die gezielte Anwendung von Massagen zur Heilung hat ihren Ursprung sehr wahrscheinlich im Osten Afrikas und in Asien (Ägypten, China, Persien). Die ersten Erwähnungen finden sich beim Chinesen Huáng Dì, der bereits 2600 v. Chr. Massagehandgriffe und gymnastische Übungen beschreibt. In Verbindung mit ätherischen Ölen und Kräutern gibt es auch frühe Nachweise in der indischen Ayurveda. Über den griechischen Arzt Hippokrates (460-375 v. Chr.) gelangt die Massage letztendlich nach Europa. Hier spielt sie eine essentielle Rolle bei der Rehabilitation der Gladiatoren. Hippokrates erkundet und vertieft die Geheimnisse der Massage und schreibt seine Erkenntnisse und Tipps zur Anwendung nieder. Der zweite bedeutende Arzt der Antike, der Römer Claudius Galenus (129-199), nahm sich ebenfalls der manuellen Therapie an und schrieb unzählige Abhandlungen über die von ihm entworfenen Massageformen und bei welchen Erkrankungen diese anzuwenden seien. Trotz seines Einflusses, der bis weit i\n" +
                "\n" +
                "n das Mittelalter reicht, verliert die Gesellschaft das Interesse an Massagen und anderen Präventions- und Therapiemaßnahmen.",
                target: "/",
                Post: false,
                date: 20181203,
            },
            {
                name: 'massagenWiederentdeckungTitle',
                value: "Wiederentdeckung",
                target: "/",
                Post: false,
                date: 20181203,
            },
            {
                name: 'massagenWiederentdeckungText',
                value: "Erst gegen Ende des Mittelalters, im 16. Jahrhundert, wurde die Massage durch den Arzt und Alchimisten Paracelsus (1493-1541) wieder Thema der Medizin. Allerdings sträubte dieser sich gegen die Lehren des Galenus, insbesondere gegen die Viersäftelehre, und machte sich damit unter seinen Kollegen viele Feinde. Es brauchte einen weiteren Arzt, den Franzosen Ambroise Paré (1510-1590), um die Massage in der modernen Medizin zu etablieren. Er verwendet die Massage als Rehabilitationstherapie nach Operationen",
                target: "/",
                Post: false,
                date: 20181203,
            },

        ]
    },
    mutations: {
        toggleHome (state) {
            state.home = !state.home;
        },
        homeTrue (state) {
            state.home = true;
        },
        homeFalse (state) {
            state.home = false;
        },
        toggleFooter (state) {
            state.footer = !state.footer;
        },
        footerTrue (state) {
            state.footer = true;
        },
        angeboteMenuTrue (state) {
            state.angeboteMenu = true;
        },
        massagenMenuTrue (state) {
            state.massagenMenu = true;
        },
        angeboteMenuFalse (state) {
            state.angeboteMenu = false;
        },
        massagenMenuFalse (state) {
            state.massagenMenu = false;
        },
        angeboteMenuToggle (state) {
            state.angeboteMenu = !state.angeboteMenu;
        },
        massagenMenuToggle (state) {
            state.massagenMenu = !state.massagenMenu;
        }
    },
    actions: {
        toggleHome (store) {
            store.commit({
                type: 'toggleHome'
            })
        },
        homeTrue (store) {
            store.commit({
                type: 'homeTrue'
            })
        },
        homeFalse (store) {
            store.commit({
                type: 'homeFalse'
            })
        },
        toggleFooter (store) {
            store.commit({
                type: 'toggleFooter'
            })
        },
        footerTrue (store) {
            store.commit({
                type: 'footerTrue'
            })
        },
        angeboteTrue (store) {
            store.commit({
                type: 'angeboteMenuTrue'
            })
        },
        massagenTrue (store) {
            store.commit({
                type: 'massagenMenuTrue'
            })
        },
        angeboteFalse (store) {
            store.commit({
                type: 'angeboteMenuFalse'
            })
        },
        massagenFalse (store) {
            store.commit({
                type: 'massagenMenuFalse'
            })
        },
        angeboteToggle (store) {
            store.commit({
                type: 'angeboteMenuToggle'
            })
        },
        massagenToggle (store) {
            store.commit({
                type: 'massagenMenuToggle'
            })
        }
    },
    getters: {
        text (state) {

            return state.text
        },
        home (state) {
            return state.home
        },
        footer (state) {
            return state.footer
        },
        angeboteMenu  (state) {
            return state.angeboteMenu
        },
        massagenMenu  (state) {
            return state.massagenMenu
        }
    }
        
})

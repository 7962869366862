<template>
    <v-container class="menuElement menu hidden-sm-and-down hidden-lg-and-up fixedCentered pb-0 z2">
        <v-layout row wrap align-center class="text-xs-center mt-5 ml-5 mr-5 pt-4">
            <v-flex xs12 >

                <v-container grid-list-md flat class="pb-0" >
                    <v-layout row wrap class="text-xs-center mt-3 pt-5">

                        <v-flex xs2
                                @click="navPage('/')" >
                            <v-btn flat block >Home</v-btn>
                        </v-flex>
                        <v-flex xs2 class="hidden-sm-and-down hidden-lg-and-up" >
                            <v-btn flat block class="pl-2" >
                                <v-container class="pt-2 ma-0 pl-0 pr-0 text-xs-center">
                                    <v-layout row wrap>
                                        <v-flex
                                                @click="navPage('/Massage')"
                                                xs8
                                        >
                                            Massagen
                                        </v-flex>
                                        <v-flex v-if="massagenMenuComp"
                                                @click="massagenMenuNotActive()"
                                                class="text-xs-right pl-0 pt-1"
                                                xs2 offset-xs2
                                        >
                                            <v-icon class="dontMark link" >
                                                arrow_drop_up
                                            </v-icon>
                                        </v-flex>
                                        <v-flex v-else
                                                @click="massagenMenuActive()"
                                                class="text-xs-right pl-1 pt-0"
                                                xs2 offset-xs2
                                        >
                                            <v-icon class="dontMark link">
                                                arrow_drop_down
                                            </v-icon>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-btn>
                        </v-flex>
                        <v-flex xs2
                                @click="navPage('/Angebote')" >
                            <v-btn flat block >Gutscheine</v-btn>
                        </v-flex>
                        <v-flex xs2
                                @click="navPage('/person')" >
                            <v-btn flat block >Zur Person</v-btn>
                        </v-flex>
                        <v-flex xs2
                                @click="navPage('/Kontakt')" >
                            <v-btn flat block >Kontakt</v-btn>
                        </v-flex>
                        <v-flex xs2
                                @click="navPage('/Verordnung')" >
                            <v-btn flat block >Verordnung</v-btn>
                        </v-flex>
                    </v-layout>
                </v-container>

            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import router from '../../router.js';
    import store from '../../store';
    export default {
        name: "Desktopmenue",
        data () {
            return {
                massagenMenu : false,
                angeboteMenu: false,
                clipped: true,
                drawer: false,
                maintanance: false,
                maintananceMobile: false,
                maintananceMessage: false,
            }
        },
        methods: {
            navPage (page) {
                router.push({path: page});
            },
            massagenMenuActive () {
                store.dispatch('massagenTrue')
            },
            massagenMenuNotActive () {
                store.dispatch('massagenFalse')
            },
            massagenMenuToggle () {
                store.dispatch('massagenToggle')
            },
            angeboteMenuActive () {
                store.dispatch('angeboteTrue')
            },
            angeboteMenuNotActive () {
                store.dispatch('angeboteFalse')
            },
            angeboteMenuToggle () {
                store.dispatch('angeboteToggle')
            },
            toggleDrawer() {
                this.drawer = !this.drawer
            },
            toggleMaintananceMessage() {
                this.maintananceMessage = !this.maintananceMessage;
            },
            toggleMaintananceMobile() {
                this.maintananceMobile = !this.maintananceMobile;
            },
            closeMaintananceMessage() {
                this.maintanance = false;
            }
        },
        computed: {
            home () {
                return this.$store.getters.home
            },
            footer () {
                return this.$store.getters.footer
            },
            massagenMenuComp() {
                return this.$store.getters.massagenMenu
            },
            angeboteMenuComp() {
                return this.$store.getters.angeboteMenu
            }
        }

    }
</script>

<style scoped>

</style>
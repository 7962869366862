import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            title: 'Chicasa - Home',
        },
        component: () => import(/* webpackChunkName: "Home" */ './views/Home.vue')
    },
    {
        path: '/Massage',
        name: 'massage',
        meta: {
            title: 'Chicasa - Massagen',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "register" */ './views/Massagen.vue')
    },
    {
        path: '/Angebote',
        name: 'Angebote',
        meta: {
            title: 'Chicasa - Angebote',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "info" */ './views/Angebote.vue')
    },
    {
        path: '/Person',
        name: 'Person',
        meta: {
            title: 'Chicasa - Person',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "info" */ './views/Person.vue')
    },
    {
        path: '/Kontakt',
        name: 'Kontakt',
        meta: {
            title: 'Chicasa - Kontakt',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "info" */ './views/Kontakt.vue')
    },
    {
        path: '/Verordnung',
        name: 'Verordnung',
        meta: {
            title: 'Chicasa - Verordnung',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "info" */ './views/Verordnung.vue')
    },
    {
        path: '/Impressum',
        name: 'Impressum',
        meta: {
            title: 'Chicasa - Impressum',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "info" */ './views/Impressum.vue')
    },
    {
        path: '/Massagen/Klassisch',
        name: 'Klassisch',
        meta: {
            title: 'Chicasa - Klassisch',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "info" */ './views/Massagen/Klassisch.vue')
    },
    {
        path: '/Massagen/Ruecken',
        name: 'Ruecken',
        meta: {
            title: 'Chicasa - Rücken',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "info" */ './views/Massagen/Ruecken.vue')
    },
    {
        path: '/Massagen/Sport',
        name: 'Sport',
        meta: {
            title: 'Chicasa - Sport',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "info" */ './views/Massagen/Sport.vue')
    },
    {
        path: '/Massagen/Akupunktur',
        name: 'Akupunktur',
        meta: {
            title: 'Chicasa - Akupunktur',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "info" */ './views/Massagen/Akupunktur.vue')
    },
    {
        path: '/Massagen/Bindegewebe',
        name: 'Bindegewebe',
        meta: {
            title: 'Chicasa - Bindegewebe',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "info" */ './views/Massagen/Bindegewebe.vue')
    },
    {
        path: '/Massagen/Fussreflexzonen',
        name: 'Fussreflexzonen',
        meta: {
            title: 'Chicasa - Fussreflexzonen',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "info" */ './views/Massagen/Fussreflexzonen.vue')
    },
    {
        path: '/Massagen/Lymphdrainage',
        name: 'Lymphdrainage',
        meta: {
            title: 'Chicasa - Lymphdrainage',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "info" */ './views/Massagen/Lymphdrainage.vue')
    },
    {
        path: '/Massagen/Wirkung',
        name: 'Wirkung',
        meta: {
            title: 'Chicasa - Wirkung',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "info" */ './views/Massagen/Wirkung.vue')
    },
];

const router = new Router({
    mode: 'history',
    routes: routes,
});



// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Skip rendering meta tags if there are none.
    return next();

});


export default router;
